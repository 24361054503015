
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  detectRuntime,
} = require('./runtime/index-browser')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.6.0
 * Query Engine version: e95e739751f42d8ca026f6b910f5a2dc5adeaeee
 */
Prisma.prismaVersion = {
  client: "5.6.0",
  engine: "e95e739751f42d8ca026f6b910f5a2dc5adeaeee"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.AccountScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state',
  ext_expires_in: 'ext_expires_in'
};

exports.Prisma.SessionScalarFieldEnum = {
  id: 'id',
  sessionToken: 'sessionToken',
  userId: 'userId',
  expires: 'expires'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  emailVerified: 'emailVerified',
  image: 'image',
  password: 'password'
};

exports.Prisma.VerificationTokenScalarFieldEnum = {
  identifier: 'identifier',
  token: 'token',
  expires: 'expires'
};

exports.Prisma.MembershipScalarFieldEnum = {
  id: 'id',
  role: 'role',
  invitedName: 'invitedName',
  invitedEmail: 'invitedEmail',
  removed: 'removed',
  type: 'type',
  organizationId: 'organizationId',
  userId: 'userId'
};

exports.Prisma.OrganizationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  location: 'location',
  brandLogo: 'brandLogo',
  defaultTemplateBidFormId: 'defaultTemplateBidFormId',
  defaultWageTypeId: 'defaultWageTypeId',
  oneSchemaId: 'oneSchemaId'
};

exports.Prisma.OrganizationOfficeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  phone: 'phone',
  secondaryPhone: 'secondaryPhone',
  url: 'url',
  organizationId: 'organizationId'
};

exports.Prisma.MembershipOfficeScalarFieldEnum = {
  officeId: 'officeId',
  membershipId: 'membershipId'
};

exports.Prisma.ChecklistScalarFieldEnum = {
  id: 'id',
  projectId: 'projectId'
};

exports.Prisma.ChecklistTemplateScalarFieldEnum = {
  id: 'id',
  organizationId: 'organizationId'
};

exports.Prisma.TaskTemplateScalarFieldEnum = {
  id: 'id',
  label: 'label',
  order: 'order',
  checklistTemplateId: 'checklistTemplateId'
};

exports.Prisma.TaskScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  isDone: 'isDone',
  label: 'label',
  order: 'order',
  checklistId: 'checklistId'
};

exports.Prisma.CsiCodeScalarFieldEnum = {
  id: 'id',
  division: 'division',
  code: 'code',
  cleanCode: 'cleanCode',
  orderableCode: 'orderableCode',
  description: 'description',
  organizationId: 'organizationId'
};

exports.Prisma.OwnerOrganizationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.OwnerOrganizationMemberScalarFieldEnum = {
  id: 'id',
  name: 'name',
  phone: 'phone',
  email: 'email',
  organizationId: 'organizationId',
  ownerOrganizationId: 'ownerOrganizationId'
};

exports.Prisma.ProjectAssigneeScalarFieldEnum = {
  projectId: 'projectId',
  membershipId: 'membershipId'
};

exports.Prisma.ProjectStagesConfigurationScalarFieldEnum = {
  id: 'id',
  organizationId: 'organizationId'
};

exports.Prisma.ProjectStageScalarFieldEnum = {
  id: 'id',
  orderId: 'orderId',
  name: 'name',
  color: 'color',
  configId: 'configId'
};

exports.Prisma.ProjectStageAssignmentScalarFieldEnum = {
  id: 'id',
  orderId: 'orderId',
  name: 'name',
  color: 'color',
  projectId: 'projectId',
  currentProjectId: 'currentProjectId'
};

exports.Prisma.ProjectScalarFieldEnum = {
  id: 'id',
  currentEstimateVersion: 'currentEstimateVersion',
  projectSize: 'projectSize',
  code: 'code',
  address: 'address',
  cityState: 'cityState',
  name: 'name',
  startDate: 'startDate',
  completionDate: 'completionDate',
  bidDueDate: 'bidDueDate',
  rfiDueDate: 'rfiDueDate',
  projectValue: 'projectValue',
  currency: 'currency',
  targetDate: 'targetDate',
  description: 'description',
  status: 'status',
  projectDuration: 'projectDuration',
  deleted: 'deleted',
  architectId: 'architectId',
  projectTypeId: 'projectTypeId',
  bidFormTemplateId: 'bidFormTemplateId',
  organizationId: 'organizationId',
  ownerId: 'ownerId',
  clientId: 'clientId',
  ownerOrganizationMemberId: 'ownerOrganizationMemberId'
};

exports.Prisma.ArchitectScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.ProjectTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.StakeholderScalarFieldEnum = {
  id: 'id',
  name: 'name',
  title: 'title',
  email: 'email',
  phone: 'phone',
  projectId: 'projectId',
  organizationId: 'organizationId'
};

exports.Prisma.ClientScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.ProjectTagScalarFieldEnum = {
  projectId: 'projectId',
  tagId: 'tagId'
};

exports.Prisma.SubcontractorTagScalarFieldEnum = {
  subcontractorId: 'subcontractorId',
  tagId: 'tagId'
};

exports.Prisma.TagScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.CommentScalarFieldEnum = {
  id: 'id',
  text: 'text',
  data: 'data',
  resolve: 'resolve',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dbId: 'dbId',
  bidderId: 'bidderId',
  takeoffId: 'takeoffId',
  parentId: 'parentId',
  assignedToId: 'assignedToId',
  createdById: 'createdById'
};

exports.Prisma.MentionScalarFieldEnum = {
  id: 'id',
  commentId: 'commentId',
  email: 'email'
};

exports.Prisma.SubContractorOrganizationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  location: 'location',
  preferredStatus: 'preferredStatus',
  laborTypes: 'laborTypes',
  website: 'website',
  streetAddress: 'streetAddress',
  city: 'city',
  zipCode: 'zipCode',
  state: 'state',
  qualificationStatusUpdated: 'qualificationStatusUpdated',
  perProject: 'perProject',
  perAnnum: 'perAnnum',
  externalId: 'externalId',
  organizationId: 'organizationId',
  qualificationStatus: 'qualificationStatus'
};

exports.Prisma.SubContractorOrganizationDBECertificateScalarFieldEnum = {
  subcontractorOrganizationId: 'subcontractorOrganizationId',
  certificateId: 'certificateId'
};

exports.Prisma.DBECertificateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.SubContractorOrganizationMemberScalarFieldEnum = {
  id: 'id',
  name: 'name',
  phone: 'phone',
  officePhone: 'officePhone',
  cellPhone: 'cellPhone',
  email: 'email',
  title: 'title',
  isOwner: 'isOwner',
  removed: 'removed',
  organizationId: 'organizationId',
  subcontractorOrganizationId: 'subcontractorOrganizationId'
};

exports.Prisma.EnterpriseTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.SubcontractorOrganizationEnterpriseTypeScalarFieldEnum = {
  enterpriseTypeId: 'enterpriseTypeId',
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.BidStakeholderScalarFieldEnum = {
  id: 'id',
  subcontractorOrganizationMemberId: 'subcontractorOrganizationMemberId',
  bidderId: 'bidderId'
};

exports.Prisma.BidderScalarFieldEnum = {
  id: 'id',
  qualification: 'qualification',
  status: 'status',
  subcontractorOrganizationId: 'subcontractorOrganizationId',
  bidId: 'bidId'
};

exports.Prisma.BidderAutomatedEmailRecordScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  bidderId: 'bidderId'
};

exports.Prisma.SubcontractorBidderJoinerScalarFieldEnum = {
  id: 'id',
  email: 'email',
  bidderId: 'bidderId'
};

exports.Prisma.TradeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  code: 'code',
  organizationId: 'organizationId'
};

exports.Prisma.SubContractorOrganizationTradeScalarFieldEnum = {
  tradeId: 'tradeId',
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.SubContractorOrganizationCsiCodeScalarFieldEnum = {
  csiCodeId: 'csiCodeId',
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.BidTradeAssignmentScalarFieldEnum = {
  id: 'id',
  tradeId: 'tradeId',
  bidId: 'bidId'
};

exports.Prisma.BidScalarFieldEnum = {
  id: 'id',
  name: 'name',
  stage: 'stage',
  amount: 'amount',
  currency: 'currency',
  shortLink: 'shortLink',
  deleted: 'deleted',
  cancelled: 'cancelled',
  dueDate: 'dueDate',
  rfiDueDate: 'rfiDueDate',
  expectStart: 'expectStart',
  expectFinish: 'expectFinish',
  projectId: 'projectId',
  csiCodeId: 'csiCodeId',
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BidPackageTemplateScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  name: 'name',
  description: 'description',
  organizationId: 'organizationId',
  templateBidFormId: 'templateBidFormId',
  csiCodeId: 'csiCodeId'
};

exports.Prisma.BulkBidPackageTemplateAssignmentScalarFieldEnum = {
  id: 'id',
  bulkBidPackageTemplateId: 'bulkBidPackageTemplateId',
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BulkBidPackageTemplateScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt',
  name: 'name',
  description: 'description',
  deleted: 'deleted',
  organizationId: 'organizationId'
};

exports.Prisma.BidderListTemplateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  organizationId: 'organizationId'
};

exports.Prisma.BidderListTemplateSubContractorJoinerScalarFieldEnum = {
  id: 'id',
  bidderListTemplateId: 'bidderListTemplateId',
  subcontractorId: 'subcontractorId'
};

exports.Prisma.BidderTemplateScalarFieldEnum = {
  id: 'id',
  bidTemplateId: 'bidTemplateId',
  subcontractorOrganizationId: 'subcontractorOrganizationId'
};

exports.Prisma.BidderStakeholderJoinerScalarFieldEnum = {
  id: 'id',
  stakeholderId: 'stakeholderId',
  bidderId: 'bidderId'
};

exports.Prisma.BidPackageTemplateTradeAssignmentScalarFieldEnum = {
  id: 'id',
  tradeId: 'tradeId',
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BidActionEventScalarFieldEnum = {
  id: 'id',
  type: 'type',
  data: 'data',
  bidId: 'bidId',
  updatedAt: 'updatedAt'
};

exports.Prisma.TemplateBidFormScalarFieldEnum = {
  id: 'id',
  organizationId: 'organizationId',
  versionId: 'versionId'
};

exports.Prisma.BidFormTemplateScalarFieldEnum = {
  id: 'id',
  bidId: 'bidId',
  organizationId: 'organizationId'
};

exports.Prisma.BidFormTemplateVersionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  version: 'version',
  updatedAt: 'updatedAt',
  bidFormTemplateId: 'bidFormTemplateId'
};

exports.Prisma.BidFormTemplateSectionScalarFieldEnum = {
  id: 'id',
  title: 'title',
  orderId: 'orderId',
  updatedAt: 'updatedAt',
  versionId: 'versionId',
  projectId: 'projectId'
};

exports.Prisma.BidFormQuestionRepresentationScalarFieldEnum = {
  id: 'id',
  question: 'question',
  responseType: 'responseType',
  required: 'required',
  orderId: 'orderId',
  updatedAt: 'updatedAt',
  baselineData: 'baselineData',
  estimateLineItemId: 'estimateLineItemId',
  internalDescription: 'internalDescription',
  internalCode: 'internalCode',
  deleted: 'deleted'
};

exports.Prisma.BidFormTemplateQuestionScalarFieldEnum = {
  id: 'id',
  sectionId: 'sectionId',
  liveId: 'liveId',
  draftId: 'draftId',
  projectId: 'projectId'
};

exports.Prisma.BidFormResponseScalarFieldEnum = {
  id: 'id',
  bidFormTemplateVersionId: 'bidFormTemplateVersionId',
  bidderId: 'bidderId'
};

exports.Prisma.BidFormResponseVersionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  submitted: 'submitted',
  submittedAt: 'submittedAt',
  updatedAt: 'updatedAt',
  bidFormResponseId: 'bidFormResponseId'
};

exports.Prisma.BidFormResponseSectionScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  responseVersionId: 'responseVersionId',
  templateSectionId: 'templateSectionId'
};

exports.Prisma.BidFormResponseQuestionScalarFieldEnum = {
  id: 'id',
  notes: 'notes',
  awarded: 'awarded',
  data: 'data',
  updatedAt: 'updatedAt',
  deleted: 'deleted',
  sectionId: 'sectionId',
  templateQuestionId: 'templateQuestionId'
};

exports.Prisma.BidLevelEventScalarFieldEnum = {
  id: 'id',
  type: 'type',
  data: 'data',
  bidFormResponseQuestionId: 'bidFormResponseQuestionId',
  createdById: 'createdById',
  createdAt: 'createdAt'
};

exports.Prisma.SubcontractorMembershipScalarFieldEnum = {
  id: 'id',
  invitedEmail: 'invitedEmail',
  role: 'role',
  userId: 'userId'
};

exports.Prisma.SubcontractorQualificationFormScalarFieldEnum = {
  subcontractorId: 'subcontractorId',
  qualificationFormId: 'qualificationFormId'
};

exports.Prisma.QualificationFormScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  organizationSubContractorId: 'organizationSubContractorId',
  qualificationTemplateVersionId: 'qualificationTemplateVersionId',
  organizationId: 'organizationId'
};

exports.Prisma.QualificationTemplateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId'
};

exports.Prisma.QualificationTemplateVersionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  qualificationTemplateId: 'qualificationTemplateId'
};

exports.Prisma.QualificationQuestionTemplateScalarFieldEnum = {
  id: 'id',
  liveId: 'liveId',
  draftId: 'draftId',
  qualificationTemplateVersionId: 'qualificationTemplateVersionId'
};

exports.Prisma.QualificationQuestionTemplateRepresentationScalarFieldEnum = {
  id: 'id',
  question: 'question',
  responseType: 'responseType',
  required: 'required',
  orderId: 'orderId',
  deleted: 'deleted',
  updatedAt: 'updatedAt'
};

exports.Prisma.QualificationQuestionResponseScalarFieldEnum = {
  id: 'id',
  updatedAt: 'updatedAt',
  response: 'response',
  responseOverride: 'responseOverride',
  qualificationQuestionTemplateId: 'qualificationQuestionTemplateId',
  qualificationFormId: 'qualificationFormId'
};

exports.Prisma.OneSchemaIntegrationScalarFieldEnum = {
  id: 'id',
  estimate: 'estimate',
  bidLevel: 'bidLevel',
  subContractor: 'subContractor',
  laborActions: 'laborActions',
  laborBurderns: 'laborBurderns',
  materialCatalog: 'materialCatalog'
};

exports.Prisma.NotificationScalarFieldEnum = {
  id: 'id',
  createdBy: 'createdBy',
  source: 'source',
  createdAt: 'createdAt',
  type: 'type'
};

exports.Prisma.NotificationMembershipJoinerScalarFieldEnum = {
  id: 'id',
  seenAt: 'seenAt',
  notificationId: 'notificationId',
  membershipId: 'membershipId'
};

exports.Prisma.NotificationSubcontractorMembershipJoinerScalarFieldEnum = {
  id: 'id',
  seenAt: 'seenAt',
  notificationId: 'notificationId',
  subMembershipId: 'subMembershipId'
};

exports.Prisma.TakeoffScalarFieldEnum = {
  id: 'id',
  name: 'name',
  uiState: 'uiState',
  yDoc: 'yDoc',
  diff: 'diff',
  updatedAt: 'updatedAt',
  organizationId: 'organizationId',
  projectId: 'projectId'
};

exports.Prisma.PlanScalarFieldEnum = {
  id: 'id',
  name: 'name',
  page: 'page',
  sheetNumber: 'sheetNumber',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  takeoffId: 'takeoffId'
};

exports.Prisma.PlanVersionScalarFieldEnum = {
  id: 'id',
  version: 'version',
  sourceDocumentUrl: 'sourceDocumentUrl',
  thumbnailUrl: 'thumbnailUrl',
  originalUrl: 'originalUrl',
  snapshotUrl: 'snapshotUrl',
  scale: 'scale',
  scaleUnitFactor: 'scaleUnitFactor',
  calibratedScale: 'calibratedScale',
  calibratedDpi: 'calibratedDpi',
  metadata: 'metadata',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  order: 'order',
  planId: 'planId'
};

exports.Prisma.ComponentGroupScalarFieldEnum = {
  id: 'id',
  deleted: 'deleted',
  name: 'name',
  countedGroup: 'countedGroup',
  takeoffId: 'takeoffId',
  parentGroupId: 'parentGroupId',
  organizationId: 'organizationId'
};

exports.Prisma.ComponentScalarFieldEnum = {
  id: 'id',
  deleted: 'deleted',
  name: 'name',
  type: 'type',
  specificType: 'specificType',
  shape: 'shape',
  color: 'color',
  opacity: 'opacity',
  textureType: 'textureType',
  description: 'description',
  componentNumber: 'componentNumber',
  itemType: 'itemType',
  multiplier: 'multiplier',
  atticStock: 'atticStock',
  waste: 'waste',
  takeoffId: 'takeoffId',
  organizationId: 'organizationId',
  csiCodeId: 'csiCodeId',
  componentItemTypeId: 'componentItemTypeId'
};

exports.Prisma.MeasurementScalarFieldEnum = {
  id: 'id',
  deleted: 'deleted',
  measuredAmount: 'measuredAmount',
  subtractedAmount: 'subtractedAmount',
  vertices: 'vertices',
  componentId: 'componentId',
  takeoffId: 'takeoffId',
  planId: 'planId',
  organizationId: 'organizationId',
  areaId: 'areaId',
  alternateId: 'alternateId',
  phaseId: 'phaseId'
};

exports.Prisma.MeasurementEventScalarFieldEnum = {
  id: 'id',
  measurementId: 'measurementId',
  action: 'action',
  old: 'old',
  new: 'new',
  createdAt: 'createdAt',
  createdById: 'createdById',
  organizationId: 'organizationId'
};

exports.Prisma.ComponentEventScalarFieldEnum = {
  id: 'id',
  action: 'action',
  old: 'old',
  new: 'new',
  createdById: 'createdById',
  createdAt: 'createdAt',
  componentId: 'componentId',
  organizationId: 'organizationId'
};

exports.Prisma.ComponentGroupEventScalarFieldEnum = {
  id: 'id',
  action: 'action',
  old: 'old',
  new: 'new',
  createdAt: 'createdAt',
  componentGroupId: 'componentGroupId',
  createdById: 'createdById',
  organizationId: 'organizationId'
};

exports.Prisma.AreaScalarFieldEnum = {
  id: 'id',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  parentId: 'parentId',
  organizationId: 'organizationId',
  takeoffId: 'takeoffId'
};

exports.Prisma.AlternateMeasurementTagScalarFieldEnum = {
  id: 'id',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  organizationId: 'organizationId',
  takeoffId: 'takeoffId'
};

exports.Prisma.PhaseMeasurementTagScalarFieldEnum = {
  id: 'id',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  organizationId: 'organizationId',
  takeoffId: 'takeoffId'
};

exports.Prisma.ComponentDetailScalarFieldEnum = {
  id: 'id',
  deleted: 'deleted',
  version: 'version',
  updatedAt: 'updatedAt',
  componentId: 'componentId',
  organizationId: 'organizationId'
};

exports.Prisma.FeatureFlagScalarFieldEnum = {
  name: 'name',
  allowedPaths: 'allowedPaths'
};

exports.Prisma.OrganizationFeatureFlagScalarFieldEnum = {
  isEnabled: 'isEnabled',
  organizationId: 'organizationId',
  featureFlagId: 'featureFlagId'
};

exports.Prisma.DocumentSharingScalarFieldEnum = {
  id: 'id',
  filePath: 'filePath',
  targetUserEmail: 'targetUserEmail',
  expiresAt: 'expiresAt',
  createdAt: 'createdAt',
  organizationId: 'organizationId'
};

exports.Prisma.MasterItemCatalogScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.MasterItemScalarFieldEnum = {
  id: 'id',
  code: 'code',
  uom: 'uom',
  description: 'description',
  manufacturer: 'manufacturer',
  itemSetup: 'itemSetup',
  calculationMethod: 'calculationMethod',
  componentQtyType: 'componentQtyType',
  installationMethodHorizontal: 'installationMethodHorizontal',
  installationMethodContinuous: 'installationMethodContinuous',
  layers: 'layers',
  height: 'height',
  oc: 'oc',
  waste: 'waste',
  sizeMethod: 'sizeMethod',
  container: 'container',
  materialCostMethod: 'materialCostMethod',
  materialCostCodeId: 'materialCostCodeId',
  laborCostMethod: 'laborCostMethod',
  laborCostCodeId: 'laborCostCodeId',
  csiCodeId: 'csiCodeId',
  organizationId: 'organizationId',
  masterItemCatalogId: 'masterItemCatalogId',
  parentItemId: 'parentItemId',
  wageTypeId: 'wageTypeId',
  itemTypeId: 'itemTypeId',
  sizeDescription: 'sizeDescription',
  sizeProperties: 'sizeProperties',
  materialCost: 'materialCost',
  productionRate: 'productionRate',
  laborCost: 'laborCost',
  per: 'per',
  productNumber: 'productNumber',
  style: 'style',
  type: 'type'
};

exports.Prisma.MasterItemLaborCostScalarFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  laborCostMethod: 'laborCostMethod',
  updatedAt: 'updatedAt',
  productionRate: 'productionRate',
  masterItemSizeId: 'masterItemSizeId'
};

exports.Prisma.MasterItemMaterialCostScalarFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  materialCostMethod: 'materialCostMethod',
  updatedAt: 'updatedAt',
  cost: 'cost',
  per: 'per',
  masterItemSizeId: 'masterItemSizeId'
};

exports.Prisma.MasterItemSizeScalarFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  sizeMethod: 'sizeMethod',
  per: 'per',
  productNumber: 'productNumber',
  weight: 'weight',
  width: 'width',
  length: 'length',
  style: 'style'
};

exports.Prisma.ProjectItemScalarFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  projectId: 'projectId',
  code: 'code',
  uom: 'uom',
  description: 'description',
  manufacturer: 'manufacturer',
  itemSetup: 'itemSetup',
  calculationMethod: 'calculationMethod',
  sizeMethod: 'sizeMethod',
  container: 'container',
  materialCostMethod: 'materialCostMethod',
  laborCostMethod: 'laborCostMethod',
  organizationId: 'organizationId',
  wageTypeId: 'wageTypeId',
  itemTypeId: 'itemTypeId'
};

exports.Prisma.ProjectItemLaborCostScalarFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemLaborCostId: 'masterItemLaborCostId',
  laborCostMethod: 'laborCostMethod',
  updatedAt: 'updatedAt',
  productionRate: 'productionRate',
  projectItemSizeId: 'projectItemSizeId'
};

exports.Prisma.ProjectItemMaterialCostScalarFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemMaterialCostId: 'masterItemMaterialCostId',
  materialCostMethod: 'materialCostMethod',
  updatedAt: 'updatedAt',
  cost: 'cost',
  per: 'per',
  projectItemSizeId: 'projectItemSizeId'
};

exports.Prisma.ProjectItemSizeScalarFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemSizeId: 'masterItemSizeId',
  sizeMethod: 'sizeMethod',
  per: 'per',
  productNumber: 'productNumber',
  weight: 'weight',
  width: 'width',
  length: 'length',
  style: 'style'
};

exports.Prisma.ProjectItemPayrollClassScalarFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  crewSize: 'crewSize',
  esimatePayrollClassId: 'esimatePayrollClassId'
};

exports.Prisma.EstimateScalarFieldEnum = {
  id: 'id',
  version: 'version',
  name: 'name',
  description: 'description',
  organizationId: 'organizationId',
  projectId: 'projectId'
};

exports.Prisma.EstimationEventScalarFieldEnum = {
  id: 'id',
  version: 'version',
  type: 'type',
  estimationType: 'estimationType',
  data: 'data',
  createdAt: 'createdAt',
  projectId: 'projectId',
  estimateId: 'estimateId',
  organizationId: 'organizationId',
  createdByEmail: 'createdByEmail'
};

exports.Prisma.MarkupScalarFieldEnum = {
  id: 'id',
  organizationId: 'organizationId',
  version: 'version',
  projectId: 'projectId',
  csiCodeId: 'csiCodeId',
  estimateId: 'estimateId',
  stockCalculationMode: 'stockCalculationMode',
  cleanCalculationMode: 'cleanCalculationMode',
  supervisionCalculationMode: 'supervisionCalculationMode'
};

exports.Prisma.MarkupItemScalarFieldEnum = {
  id: 'id',
  order: 'order',
  enabled: 'enabled',
  title: 'title',
  materialPercentage: 'materialPercentage',
  materialValue: 'materialValue',
  laborPercentage: 'laborPercentage',
  laborValue: 'laborValue',
  subsPercentage: 'subsPercentage',
  subsValue: 'subsValue',
  equipmentPercentage: 'equipmentPercentage',
  equipmentValue: 'equipmentValue',
  othersPercentage: 'othersPercentage',
  othersValue: 'othersValue',
  generalRequirementsPercentage: 'generalRequirementsPercentage',
  generalRequirementsValue: 'generalRequirementsValue',
  generalConditionsPercentage: 'generalConditionsPercentage',
  generalConditionsValue: 'generalConditionsValue',
  totalsPercentage: 'totalsPercentage',
  totalsValue: 'totalsValue',
  markupId: 'markupId'
};

exports.Prisma.MarkupStockItemScalarFieldEnum = {
  id: 'id',
  percent: 'percent',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  wageTypeId: 'wageTypeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  installerPayrollClassId: 'installerPayrollClassId'
};

exports.Prisma.MarkupCleanItemScalarFieldEnum = {
  id: 'id',
  percent: 'percent',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  wageTypeId: 'wageTypeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  installerPayrollClassId: 'installerPayrollClassId'
};

exports.Prisma.MarkupSupervisionItemScalarFieldEnum = {
  id: 'id',
  percent: 'percent',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  wageTypeId: 'wageTypeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  supervisorPayrollClassId: 'supervisorPayrollClassId'
};

exports.Prisma.MarkupPerDiemItemScalarFieldEnum = {
  id: 'id',
  perHour: 'perHour',
  perDay: 'perDay',
  totalCost: 'totalCost',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.WageTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.BurdenTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  rateType: 'rateType',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.PayrollClassBurdenScalarFieldEnum = {
  id: 'id',
  name: 'name',
  rateType: 'rateType',
  rate: 'rate',
  burdenTypeId: 'burdenTypeId',
  masterWageTypePayrollClassDefinitionId: 'masterWageTypePayrollClassDefinitionId',
  estimateWageTypePayrollClassDefinitionId: 'estimateWageTypePayrollClassDefinitionId'
};

exports.Prisma.MasterWageTypePayrollClassDefinitionScalarFieldEnum = {
  id: 'id',
  baseWage: 'baseWage',
  wageTypeId: 'wageTypeId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.EstimateWageTypePayrollClassDefinitionScalarFieldEnum = {
  id: 'id',
  baseWage: 'baseWage',
  wageTypeId: 'wageTypeId',
  estimatePayrollClassId: 'estimatePayrollClassId'
};

exports.Prisma.MasterPayrollClassScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  officeId: 'officeId',
  isDeleted: 'isDeleted'
};

exports.Prisma.MasterItemPayrollClassScalarFieldEnum = {
  id: 'id',
  crewSize: 'crewSize',
  masterItemId: 'masterItemId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.EstimatePayrollClassScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  estimateId: 'estimateId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.CostCodeScalarFieldEnum = {
  id: 'id',
  type: 'type',
  name: 'name',
  description: 'description',
  accountingCode: 'accountingCode',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.ItemTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.ComponentItemTypeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  organizationId: 'organizationId',
  officeId: 'officeId'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.AccountOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.SessionOrderByRelevanceFieldEnum = {
  id: 'id',
  sessionToken: 'sessionToken',
  userId: 'userId'
};

exports.Prisma.UserOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  image: 'image',
  password: 'password'
};

exports.Prisma.VerificationTokenOrderByRelevanceFieldEnum = {
  identifier: 'identifier',
  token: 'token'
};

exports.Prisma.MembershipOrderByRelevanceFieldEnum = {
  invitedName: 'invitedName',
  invitedEmail: 'invitedEmail',
  userId: 'userId'
};

exports.Prisma.OrganizationOrderByRelevanceFieldEnum = {
  name: 'name',
  location: 'location',
  brandLogo: 'brandLogo'
};

exports.Prisma.OrganizationOfficeOrderByRelevanceFieldEnum = {
  name: 'name',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  phone: 'phone',
  secondaryPhone: 'secondaryPhone',
  url: 'url'
};

exports.Prisma.ChecklistOrderByRelevanceFieldEnum = {
  projectId: 'projectId'
};

exports.Prisma.TaskTemplateOrderByRelevanceFieldEnum = {
  label: 'label'
};

exports.Prisma.TaskOrderByRelevanceFieldEnum = {
  label: 'label'
};

exports.Prisma.CsiCodeOrderByRelevanceFieldEnum = {
  id: 'id',
  division: 'division',
  code: 'code',
  cleanCode: 'cleanCode',
  orderableCode: 'orderableCode',
  description: 'description'
};

exports.Prisma.OwnerOrganizationOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.OwnerOrganizationMemberOrderByRelevanceFieldEnum = {
  name: 'name',
  phone: 'phone',
  email: 'email'
};

exports.Prisma.ProjectAssigneeOrderByRelevanceFieldEnum = {
  projectId: 'projectId'
};

exports.Prisma.ProjectStageOrderByRelevanceFieldEnum = {
  name: 'name',
  color: 'color'
};

exports.Prisma.ProjectStageAssignmentOrderByRelevanceFieldEnum = {
  name: 'name',
  color: 'color',
  projectId: 'projectId',
  currentProjectId: 'currentProjectId'
};

exports.Prisma.ProjectOrderByRelevanceFieldEnum = {
  id: 'id',
  code: 'code',
  address: 'address',
  cityState: 'cityState',
  name: 'name',
  description: 'description'
};

exports.Prisma.ArchitectOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.ProjectTypeOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.StakeholderOrderByRelevanceFieldEnum = {
  name: 'name',
  title: 'title',
  email: 'email',
  phone: 'phone',
  projectId: 'projectId'
};

exports.Prisma.ClientOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.ProjectTagOrderByRelevanceFieldEnum = {
  projectId: 'projectId'
};

exports.Prisma.SubcontractorTagOrderByRelevanceFieldEnum = {
  subcontractorId: 'subcontractorId'
};

exports.Prisma.TagOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.CommentOrderByRelevanceFieldEnum = {
  text: 'text',
  takeoffId: 'takeoffId'
};

exports.Prisma.MentionOrderByRelevanceFieldEnum = {
  id: 'id',
  email: 'email'
};

exports.Prisma.SubContractorOrganizationOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  location: 'location',
  website: 'website',
  streetAddress: 'streetAddress',
  city: 'city',
  zipCode: 'zipCode'
};

exports.Prisma.SubContractorOrganizationDBECertificateOrderByRelevanceFieldEnum = {
  subcontractorOrganizationId: 'subcontractorOrganizationId'
};

exports.Prisma.DBECertificateOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.SubContractorOrganizationMemberOrderByRelevanceFieldEnum = {
  name: 'name',
  phone: 'phone',
  officePhone: 'officePhone',
  cellPhone: 'cellPhone',
  email: 'email',
  title: 'title',
  subcontractorOrganizationId: 'subcontractorOrganizationId'
};

exports.Prisma.EnterpriseTypeOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.SubcontractorOrganizationEnterpriseTypeOrderByRelevanceFieldEnum = {
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.BidderOrderByRelevanceFieldEnum = {
  subcontractorOrganizationId: 'subcontractorOrganizationId',
  bidId: 'bidId'
};

exports.Prisma.SubcontractorBidderJoinerOrderByRelevanceFieldEnum = {
  email: 'email'
};

exports.Prisma.TradeOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description',
  code: 'code'
};

exports.Prisma.SubContractorOrganizationTradeOrderByRelevanceFieldEnum = {
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.SubContractorOrganizationCsiCodeOrderByRelevanceFieldEnum = {
  csiCodeId: 'csiCodeId',
  subContractorOrganizationId: 'subContractorOrganizationId'
};

exports.Prisma.BidTradeAssignmentOrderByRelevanceFieldEnum = {
  bidId: 'bidId'
};

exports.Prisma.BidOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  shortLink: 'shortLink',
  projectId: 'projectId',
  csiCodeId: 'csiCodeId',
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BidPackageTemplateOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  csiCodeId: 'csiCodeId'
};

exports.Prisma.BulkBidPackageTemplateAssignmentOrderByRelevanceFieldEnum = {
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BulkBidPackageTemplateOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description'
};

exports.Prisma.BidderListTemplateOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description'
};

exports.Prisma.BidderTemplateOrderByRelevanceFieldEnum = {
  bidTemplateId: 'bidTemplateId',
  subcontractorOrganizationId: 'subcontractorOrganizationId'
};

exports.Prisma.BidPackageTemplateTradeAssignmentOrderByRelevanceFieldEnum = {
  bidPackageTemplateId: 'bidPackageTemplateId'
};

exports.Prisma.BidActionEventOrderByRelevanceFieldEnum = {
  bidId: 'bidId'
};

exports.Prisma.BidFormTemplateOrderByRelevanceFieldEnum = {
  bidId: 'bidId'
};

exports.Prisma.BidFormTemplateVersionOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.BidFormTemplateSectionOrderByRelevanceFieldEnum = {
  title: 'title',
  projectId: 'projectId'
};

exports.Prisma.BidFormQuestionRepresentationOrderByRelevanceFieldEnum = {
  id: 'id',
  question: 'question',
  estimateLineItemId: 'estimateLineItemId',
  internalDescription: 'internalDescription',
  internalCode: 'internalCode'
};

exports.Prisma.BidFormTemplateQuestionOrderByRelevanceFieldEnum = {
  liveId: 'liveId',
  draftId: 'draftId',
  projectId: 'projectId'
};

exports.Prisma.BidFormResponseQuestionOrderByRelevanceFieldEnum = {
  notes: 'notes'
};

exports.Prisma.SubcontractorMembershipOrderByRelevanceFieldEnum = {
  invitedEmail: 'invitedEmail',
  userId: 'userId'
};

exports.Prisma.QualificationFormOrderByRelevanceFieldEnum = {
  organizationSubContractorId: 'organizationSubContractorId'
};

exports.Prisma.QualificationTemplateOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.QualificationQuestionTemplateOrderByRelevanceFieldEnum = {
  liveId: 'liveId',
  draftId: 'draftId'
};

exports.Prisma.QualificationQuestionTemplateRepresentationOrderByRelevanceFieldEnum = {
  id: 'id',
  question: 'question'
};

exports.Prisma.OneSchemaIntegrationOrderByRelevanceFieldEnum = {
  estimate: 'estimate',
  bidLevel: 'bidLevel',
  subContractor: 'subContractor',
  laborActions: 'laborActions',
  laborBurderns: 'laborBurderns',
  materialCatalog: 'materialCatalog'
};

exports.Prisma.TakeoffOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  projectId: 'projectId'
};

exports.Prisma.PlanOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  sheetNumber: 'sheetNumber',
  takeoffId: 'takeoffId'
};

exports.Prisma.PlanVersionOrderByRelevanceFieldEnum = {
  id: 'id',
  sourceDocumentUrl: 'sourceDocumentUrl',
  thumbnailUrl: 'thumbnailUrl',
  originalUrl: 'originalUrl',
  snapshotUrl: 'snapshotUrl',
  planId: 'planId'
};

exports.Prisma.ComponentGroupOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  takeoffId: 'takeoffId',
  parentGroupId: 'parentGroupId'
};

exports.Prisma.ComponentOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  color: 'color',
  description: 'description',
  componentNumber: 'componentNumber',
  itemType: 'itemType',
  takeoffId: 'takeoffId',
  csiCodeId: 'csiCodeId',
  componentItemTypeId: 'componentItemTypeId'
};

exports.Prisma.MeasurementOrderByRelevanceFieldEnum = {
  id: 'id',
  componentId: 'componentId',
  takeoffId: 'takeoffId',
  planId: 'planId',
  areaId: 'areaId',
  alternateId: 'alternateId',
  phaseId: 'phaseId'
};

exports.Prisma.MeasurementEventOrderByRelevanceFieldEnum = {
  id: 'id',
  measurementId: 'measurementId'
};

exports.Prisma.ComponentEventOrderByRelevanceFieldEnum = {
  id: 'id',
  componentId: 'componentId'
};

exports.Prisma.ComponentGroupEventOrderByRelevanceFieldEnum = {
  id: 'id',
  componentGroupId: 'componentGroupId'
};

exports.Prisma.AreaOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  parentId: 'parentId',
  takeoffId: 'takeoffId'
};

exports.Prisma.AlternateMeasurementTagOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  takeoffId: 'takeoffId'
};

exports.Prisma.PhaseMeasurementTagOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  takeoffId: 'takeoffId'
};

exports.Prisma.ComponentDetailOrderByRelevanceFieldEnum = {
  id: 'id',
  componentId: 'componentId'
};

exports.Prisma.FeatureFlagOrderByRelevanceFieldEnum = {
  name: 'name',
  allowedPaths: 'allowedPaths'
};

exports.Prisma.OrganizationFeatureFlagOrderByRelevanceFieldEnum = {
  featureFlagId: 'featureFlagId'
};

exports.Prisma.DocumentSharingOrderByRelevanceFieldEnum = {
  id: 'id',
  filePath: 'filePath',
  targetUserEmail: 'targetUserEmail'
};

exports.Prisma.MasterItemCatalogOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.MasterItemOrderByRelevanceFieldEnum = {
  id: 'id',
  code: 'code',
  uom: 'uom',
  description: 'description',
  manufacturer: 'manufacturer',
  componentQtyType: 'componentQtyType',
  container: 'container',
  materialCostCodeId: 'materialCostCodeId',
  laborCostCodeId: 'laborCostCodeId',
  csiCodeId: 'csiCodeId',
  masterItemCatalogId: 'masterItemCatalogId',
  parentItemId: 'parentItemId',
  itemTypeId: 'itemTypeId',
  sizeDescription: 'sizeDescription',
  productNumber: 'productNumber',
  style: 'style',
  type: 'type'
};

exports.Prisma.MasterItemLaborCostOrderByRelevanceFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  masterItemSizeId: 'masterItemSizeId'
};

exports.Prisma.MasterItemMaterialCostOrderByRelevanceFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  masterItemSizeId: 'masterItemSizeId'
};

exports.Prisma.MasterItemSizeOrderByRelevanceFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  productNumber: 'productNumber',
  style: 'style'
};

exports.Prisma.ProjectItemOrderByRelevanceFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  projectId: 'projectId',
  code: 'code',
  uom: 'uom',
  description: 'description',
  manufacturer: 'manufacturer',
  container: 'container',
  itemTypeId: 'itemTypeId'
};

exports.Prisma.ProjectItemLaborCostOrderByRelevanceFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemLaborCostId: 'masterItemLaborCostId',
  projectItemSizeId: 'projectItemSizeId'
};

exports.Prisma.ProjectItemMaterialCostOrderByRelevanceFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemMaterialCostId: 'masterItemMaterialCostId',
  projectItemSizeId: 'projectItemSizeId'
};

exports.Prisma.ProjectItemSizeOrderByRelevanceFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  masterItemSizeId: 'masterItemSizeId',
  productNumber: 'productNumber',
  style: 'style'
};

exports.Prisma.ProjectItemPayrollClassOrderByRelevanceFieldEnum = {
  id: 'id',
  projectItemId: 'projectItemId',
  esimatePayrollClassId: 'esimatePayrollClassId'
};

exports.Prisma.EstimateOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  projectId: 'projectId'
};

exports.Prisma.EstimationEventOrderByRelevanceFieldEnum = {
  projectId: 'projectId',
  estimateId: 'estimateId',
  createdByEmail: 'createdByEmail'
};

exports.Prisma.MarkupOrderByRelevanceFieldEnum = {
  id: 'id',
  projectId: 'projectId',
  csiCodeId: 'csiCodeId',
  estimateId: 'estimateId'
};

exports.Prisma.MarkupItemOrderByRelevanceFieldEnum = {
  id: 'id',
  title: 'title',
  materialPercentage: 'materialPercentage',
  materialValue: 'materialValue',
  laborPercentage: 'laborPercentage',
  laborValue: 'laborValue',
  subsPercentage: 'subsPercentage',
  subsValue: 'subsValue',
  equipmentPercentage: 'equipmentPercentage',
  equipmentValue: 'equipmentValue',
  othersPercentage: 'othersPercentage',
  othersValue: 'othersValue',
  generalRequirementsPercentage: 'generalRequirementsPercentage',
  generalRequirementsValue: 'generalRequirementsValue',
  generalConditionsPercentage: 'generalConditionsPercentage',
  generalConditionsValue: 'generalConditionsValue',
  totalsPercentage: 'totalsPercentage',
  totalsValue: 'totalsValue',
  markupId: 'markupId'
};

exports.Prisma.MarkupStockItemOrderByRelevanceFieldEnum = {
  id: 'id',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  installerPayrollClassId: 'installerPayrollClassId'
};

exports.Prisma.MarkupCleanItemOrderByRelevanceFieldEnum = {
  id: 'id',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  installerPayrollClassId: 'installerPayrollClassId'
};

exports.Prisma.MarkupSupervisionItemOrderByRelevanceFieldEnum = {
  id: 'id',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId',
  supervisorPayrollClassId: 'supervisorPayrollClassId'
};

exports.Prisma.MarkupPerDiemItemOrderByRelevanceFieldEnum = {
  id: 'id',
  markupId: 'markupId',
  costCodeId: 'costCodeId',
  estimatePayrollClassId: 'estimatePayrollClassId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.WageTypeOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.BurdenTypeOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.PayrollClassBurdenOrderByRelevanceFieldEnum = {
  name: 'name'
};

exports.Prisma.MasterWageTypePayrollClassDefinitionOrderByRelevanceFieldEnum = {
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.EstimateWageTypePayrollClassDefinitionOrderByRelevanceFieldEnum = {
  estimatePayrollClassId: 'estimatePayrollClassId'
};

exports.Prisma.MasterPayrollClassOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.MasterItemPayrollClassOrderByRelevanceFieldEnum = {
  id: 'id',
  masterItemId: 'masterItemId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.EstimatePayrollClassOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  estimateId: 'estimateId',
  masterPayrollClassId: 'masterPayrollClassId'
};

exports.Prisma.CostCodeOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  accountingCode: 'accountingCode'
};

exports.Prisma.ItemTypeOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.ComponentItemTypeOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name'
};
exports.MembershipRole = exports.$Enums.MembershipRole = {
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER'
};

exports.MembershipType = exports.$Enums.MembershipType = {
  PRODUCTION: 'PRODUCTION',
  TEST: 'TEST'
};

exports.Currency = exports.$Enums.Currency = {
  USD: 'USD'
};

exports.ProjectStatus = exports.$Enums.ProjectStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

exports.PreferredStatus = exports.$Enums.PreferredStatus = {
  DO_NOT_USE: 'DO_NOT_USE',
  PREFERRED: 'PREFERRED'
};

exports.State = exports.$Enums.State = {
  AK: 'AK',
  AL: 'AL',
  AR: 'AR',
  AZ: 'AZ',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DC: 'DC',
  DE: 'DE',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  IA: 'IA',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MI: 'MI',
  MN: 'MN',
  MO: 'MO',
  MS: 'MS',
  MT: 'MT',
  NC: 'NC',
  ND: 'ND',
  NE: 'NE',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NV: 'NV',
  NY: 'NY',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VA: 'VA',
  VI: 'VI',
  VT: 'VT',
  WA: 'WA',
  WI: 'WI',
  WV: 'WV',
  WY: 'WY'
};

exports.SubContractorQualification = exports.$Enums.SubContractorQualification = {
  NOT_QUALIFIED: 'NOT_QUALIFIED',
  PENDING: 'PENDING',
  QUALIFIED: 'QUALIFIED'
};

exports.LaborType = exports.$Enums.LaborType = {
  NON_UNION: 'NON_UNION',
  PREVAILING: 'PREVAILING',
  UNION: 'UNION'
};

exports.BidQualification = exports.$Enums.BidQualification = {
  NOT_QUALIFIED: 'NOT_QUALIFIED',
  QUALIFIED: 'QUALIFIED',
  TO_BE_QUALIFIED: 'TO_BE_QUALIFIED'
};

exports.BidStatus = exports.$Enums.BidStatus = {
  AWARDED: 'AWARDED',
  BID_SUBMITTED: 'BID_SUBMITTED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  LEVELED: 'LEVELED',
  PENDING: 'PENDING',
  SELF_PERFORM: 'SELF_PERFORM',
  UNDECIDED: 'UNDECIDED'
};

exports.BidStage = exports.$Enums.BidStage = {
  AWARD: 'AWARD',
  LEVEL: 'LEVEL',
  PROCESS: 'PROCESS',
  QUALIFY: 'QUALIFY',
  RFP: 'RFP'
};

exports.BidActionEventType = exports.$Enums.BidActionEventType = {
  BID_CANCELLED: 'BID_CANCELLED',
  BID_CREATED: 'BID_CREATED',
  BID_DELETED: 'BID_DELETED',
  BID_PUBLISHED: 'BID_PUBLISHED',
  BID_REMINDER_SENT: 'BID_REMINDER_SENT',
  BID_SUBMITTED: 'BID_SUBMITTED',
  BID_VIEWED: 'BID_VIEWED',
  BIDDING_STATUS_UPDATED: 'BIDDING_STATUS_UPDATED'
};

exports.QuestionResponseType = exports.$Enums.QuestionResponseType = {
  BOOLEAN: 'BOOLEAN',
  MONETARY: 'MONETARY',
  PERCENTAGE: 'PERCENTAGE',
  QUANTITY: 'QUANTITY',
  TEXT: 'TEXT'
};

exports.BidLevelEventType = exports.$Enums.BidLevelEventType = {
  BOOLEAN_RESPONSE_CHANGED: 'BOOLEAN_RESPONSE_CHANGED',
  MONETARY_RESPONSE_CHANGED: 'MONETARY_RESPONSE_CHANGED',
  PERCENTAGE_RESPONSE_CHANGED: 'PERCENTAGE_RESPONSE_CHANGED',
  QUANTITY_RESPONSE_CHANGED: 'QUANTITY_RESPONSE_CHANGED',
  TEXT_RESPONSE_CHANGED: 'TEXT_RESPONSE_CHANGED'
};

exports.QualificationQuestionResponseType = exports.$Enums.QualificationQuestionResponseType = {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  MONETARY: 'MONETARY',
  PERCENTAGE: 'PERCENTAGE',
  TEXT: 'TEXT'
};

exports.NotificationEvent = exports.$Enums.NotificationEvent = {
  BID_ASSIGNED: 'BID_ASSIGNED',
  BID_CANCELLED: 'BID_CANCELLED',
  BID_DELETED: 'BID_DELETED',
  BID_SUBMITTED: 'BID_SUBMITTED',
  COMMENT_ASSIGNED: 'COMMENT_ASSIGNED',
  COMMENT_MENTIONED: 'COMMENT_MENTIONED',
  COMMENT_RESOLVED: 'COMMENT_RESOLVED',
  QUALIFICATION_ASSIGNED: 'QUALIFICATION_ASSIGNED',
  QUALIFICATION_COMPLETED: 'QUALIFICATION_COMPLETED'
};

exports.ComponentType = exports.$Enums.ComponentType = {
  AREA: 'AREA',
  COUNT: 'COUNT',
  COUNT_GROUP: 'COUNT_GROUP',
  LINEAR: 'LINEAR'
};

exports.SpecificComponentType = exports.$Enums.SpecificComponentType = {
  GENERIC: 'GENERIC',
  PITCHED_AREA: 'PITCHED_AREA',
  PITCHED_LINEAR: 'PITCHED_LINEAR',
  SURFACE_AREA: 'SURFACE_AREA',
  VOLUME_2D: 'VOLUME_2D'
};

exports.ComponentShape = exports.$Enums.ComponentShape = {
  CIRCLE: 'CIRCLE',
  NONE: 'NONE',
  SQUARE: 'SQUARE',
  TRIANGLE: 'TRIANGLE'
};

exports.TextureType = exports.$Enums.TextureType = {
  DIAGONAL_LEFT: 'DIAGONAL_LEFT',
  DIAGONAL_RIGHT: 'DIAGONAL_RIGHT',
  HORZIONTAL: 'HORZIONTAL',
  NONE: 'NONE',
  VERTICAL: 'VERTICAL'
};

exports.Action = exports.$Enums.Action = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE'
};

exports.ItemSetup = exports.$Enums.ItemSetup = {
  MATERIAL: 'MATERIAL',
  LABOR: 'LABOR',
  MATERIAL_AND_LABOR: 'MATERIAL_AND_LABOR'
};

exports.ItemCaclulationMethod = exports.$Enums.ItemCaclulationMethod = {
  STANDARD_FORMULA: 'STANDARD_FORMULA',
  DIRECT_QUANTITY: 'DIRECT_QUANTITY',
  CONDITION_QTY: 'CONDITION_QTY',
  QUANTITY_X_HEIGHT: 'QUANTITY_X_HEIGHT'
};

exports.ItemSizeMethod = exports.$Enums.ItemSizeMethod = {
  SIZE: 'SIZE',
  STYLE: 'STYLE',
  SIZE_WITH_STYLE: 'SIZE_WITH_STYLE',
  NO_SIZE: 'NO_SIZE',
  MIXTURE: 'MIXTURE'
};

exports.CostMethod = exports.$Enums.CostMethod = {
  UOM: 'UOM',
  STYLE: 'STYLE',
  PIECE: 'PIECE',
  CONTAINER: 'CONTAINER'
};

exports.EstimationEventType = exports.$Enums.EstimationEventType = {
  ITEM_ADDED: 'ITEM_ADDED',
  ITEM_UPDATED: 'ITEM_UPDATED',
  ITEM_DELETED: 'ITEM_DELETED',
  VERSION_CREATED: 'VERSION_CREATED',
  MARKUP_UPDATED: 'MARKUP_UPDATED',
  BID_FORM_QUESTION_LINKED: 'BID_FORM_QUESTION_LINKED',
  BID_FORM_QUESTION_UNLINKED: 'BID_FORM_QUESTION_UNLINKED',
  COMMENT_ADDED: 'COMMENT_ADDED',
  COST_MODIFIED: 'COST_MODIFIED',
  CREATED: 'CREATED',
  CREATED_FROM_TEMPLATE: 'CREATED_FROM_TEMPLATE',
  LINE_ITEM_ADDED: 'LINE_ITEM_ADDED',
  LINE_ITEM_ATTRIBUTE_CHANGED: 'LINE_ITEM_ATTRIBUTE_CHANGED',
  LINE_ITEM_COST_CHANGED: 'LINE_ITEM_COST_CHANGED',
  LINE_ITEM_IMPORTED: 'LINE_ITEM_IMPORTED',
  LINE_ITEM_REMOVED: 'LINE_ITEM_REMOVED',
  SECTION_ADDED: 'SECTION_ADDED'
};

exports.EstimationType = exports.$Enums.EstimationType = {
  ESTIMATION: 'ESTIMATION',
  QUICK: 'QUICK',
  OTHER: 'OTHER',
  EQUIPMENT: 'EQUIPMENT',
  GENERAL_REQUIREMENTS: 'GENERAL_REQUIREMENTS',
  GENERAL_CONDITIONS: 'GENERAL_CONDITIONS'
};

exports.MarkupIndirectExpenseCalculationMode = exports.$Enums.MarkupIndirectExpenseCalculationMode = {
  LABOR_HOUR: 'LABOR_HOUR'
};

exports.RateType = exports.$Enums.RateType = {
  FLAT: 'FLAT',
  PERCENTAGE: 'PERCENTAGE'
};

exports.CostCodeType = exports.$Enums.CostCodeType = {
  EQUIPMENT: 'EQUIPMENT',
  LABOR: 'LABOR',
  MATERIAL: 'MATERIAL',
  SUBCONTRACTOR: 'SUBCONTRACTOR',
  OTHER: 'OTHER'
};

exports.Prisma.ModelName = {
  Account: 'Account',
  Session: 'Session',
  User: 'User',
  VerificationToken: 'VerificationToken',
  Membership: 'Membership',
  Organization: 'Organization',
  OrganizationOffice: 'OrganizationOffice',
  MembershipOffice: 'MembershipOffice',
  Checklist: 'Checklist',
  ChecklistTemplate: 'ChecklistTemplate',
  TaskTemplate: 'TaskTemplate',
  Task: 'Task',
  CsiCode: 'CsiCode',
  OwnerOrganization: 'OwnerOrganization',
  OwnerOrganizationMember: 'OwnerOrganizationMember',
  ProjectAssignee: 'ProjectAssignee',
  ProjectStagesConfiguration: 'ProjectStagesConfiguration',
  ProjectStage: 'ProjectStage',
  ProjectStageAssignment: 'ProjectStageAssignment',
  Project: 'Project',
  Architect: 'Architect',
  ProjectType: 'ProjectType',
  Stakeholder: 'Stakeholder',
  Client: 'Client',
  ProjectTag: 'ProjectTag',
  SubcontractorTag: 'SubcontractorTag',
  Tag: 'Tag',
  Comment: 'Comment',
  Mention: 'Mention',
  SubContractorOrganization: 'SubContractorOrganization',
  SubContractorOrganizationDBECertificate: 'SubContractorOrganizationDBECertificate',
  DBECertificate: 'DBECertificate',
  SubContractorOrganizationMember: 'SubContractorOrganizationMember',
  EnterpriseType: 'EnterpriseType',
  SubcontractorOrganizationEnterpriseType: 'SubcontractorOrganizationEnterpriseType',
  BidStakeholder: 'BidStakeholder',
  Bidder: 'Bidder',
  BidderAutomatedEmailRecord: 'BidderAutomatedEmailRecord',
  SubcontractorBidderJoiner: 'SubcontractorBidderJoiner',
  Trade: 'Trade',
  SubContractorOrganizationTrade: 'SubContractorOrganizationTrade',
  SubContractorOrganizationCsiCode: 'SubContractorOrganizationCsiCode',
  BidTradeAssignment: 'BidTradeAssignment',
  Bid: 'Bid',
  BidPackageTemplate: 'BidPackageTemplate',
  BulkBidPackageTemplateAssignment: 'BulkBidPackageTemplateAssignment',
  BulkBidPackageTemplate: 'BulkBidPackageTemplate',
  BidderListTemplate: 'BidderListTemplate',
  BidderListTemplateSubContractorJoiner: 'BidderListTemplateSubContractorJoiner',
  BidderTemplate: 'BidderTemplate',
  BidderStakeholderJoiner: 'BidderStakeholderJoiner',
  BidPackageTemplateTradeAssignment: 'BidPackageTemplateTradeAssignment',
  BidActionEvent: 'BidActionEvent',
  TemplateBidForm: 'TemplateBidForm',
  BidFormTemplate: 'BidFormTemplate',
  BidFormTemplateVersion: 'BidFormTemplateVersion',
  BidFormTemplateSection: 'BidFormTemplateSection',
  BidFormQuestionRepresentation: 'BidFormQuestionRepresentation',
  BidFormTemplateQuestion: 'BidFormTemplateQuestion',
  BidFormResponse: 'BidFormResponse',
  BidFormResponseVersion: 'BidFormResponseVersion',
  BidFormResponseSection: 'BidFormResponseSection',
  BidFormResponseQuestion: 'BidFormResponseQuestion',
  BidLevelEvent: 'BidLevelEvent',
  SubcontractorMembership: 'SubcontractorMembership',
  SubcontractorQualificationForm: 'SubcontractorQualificationForm',
  QualificationForm: 'QualificationForm',
  QualificationTemplate: 'QualificationTemplate',
  QualificationTemplateVersion: 'QualificationTemplateVersion',
  QualificationQuestionTemplate: 'QualificationQuestionTemplate',
  QualificationQuestionTemplateRepresentation: 'QualificationQuestionTemplateRepresentation',
  QualificationQuestionResponse: 'QualificationQuestionResponse',
  OneSchemaIntegration: 'OneSchemaIntegration',
  Notification: 'Notification',
  NotificationMembershipJoiner: 'NotificationMembershipJoiner',
  NotificationSubcontractorMembershipJoiner: 'NotificationSubcontractorMembershipJoiner',
  Takeoff: 'Takeoff',
  Plan: 'Plan',
  PlanVersion: 'PlanVersion',
  ComponentGroup: 'ComponentGroup',
  Component: 'Component',
  Measurement: 'Measurement',
  MeasurementEvent: 'MeasurementEvent',
  ComponentEvent: 'ComponentEvent',
  ComponentGroupEvent: 'ComponentGroupEvent',
  Area: 'Area',
  AlternateMeasurementTag: 'AlternateMeasurementTag',
  PhaseMeasurementTag: 'PhaseMeasurementTag',
  ComponentDetail: 'ComponentDetail',
  FeatureFlag: 'FeatureFlag',
  OrganizationFeatureFlag: 'OrganizationFeatureFlag',
  DocumentSharing: 'DocumentSharing',
  MasterItemCatalog: 'MasterItemCatalog',
  MasterItem: 'MasterItem',
  MasterItemLaborCost: 'MasterItemLaborCost',
  MasterItemMaterialCost: 'MasterItemMaterialCost',
  MasterItemSize: 'MasterItemSize',
  ProjectItem: 'ProjectItem',
  ProjectItemLaborCost: 'ProjectItemLaborCost',
  ProjectItemMaterialCost: 'ProjectItemMaterialCost',
  ProjectItemSize: 'ProjectItemSize',
  ProjectItemPayrollClass: 'ProjectItemPayrollClass',
  Estimate: 'Estimate',
  EstimationEvent: 'EstimationEvent',
  Markup: 'Markup',
  MarkupItem: 'MarkupItem',
  MarkupStockItem: 'MarkupStockItem',
  MarkupCleanItem: 'MarkupCleanItem',
  MarkupSupervisionItem: 'MarkupSupervisionItem',
  MarkupPerDiemItem: 'MarkupPerDiemItem',
  WageType: 'WageType',
  BurdenType: 'BurdenType',
  PayrollClassBurden: 'PayrollClassBurden',
  MasterWageTypePayrollClassDefinition: 'MasterWageTypePayrollClassDefinition',
  EstimateWageTypePayrollClassDefinition: 'EstimateWageTypePayrollClassDefinition',
  MasterPayrollClass: 'MasterPayrollClass',
  MasterItemPayrollClass: 'MasterItemPayrollClass',
  EstimatePayrollClass: 'EstimatePayrollClass',
  CostCode: 'CostCode',
  ItemType: 'ItemType',
  ComponentItemType: 'ComponentItemType'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        const runtime = detectRuntime()
        const edgeRuntimeName = {
          'workerd': 'Cloudflare Workers',
          'deno': 'Deno and Deno Deploy',
          'netlify': 'Netlify Edge Functions',
          'edge-light': 'Vercel Edge Functions',
        }[runtime]

        let message = 'PrismaClient is unable to run in '
        if (edgeRuntimeName !== undefined) {
          message += edgeRuntimeName + '. As an alternative, try Accelerate: https://pris.ly/d/accelerate.'
        } else {
          message += 'this browser environment, or has been bundled for the browser (running in `' + runtime + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://github.com/prisma/prisma/issues`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)

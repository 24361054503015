import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';
import { ReactElement } from 'react';

export type DefaultFallbackProps = {
  message?: string;
  content?: React.ReactNode;
  info?: string;
  image?: string | React.ReactNode;
  button?: ReactElement;
};

export type FallBackProps =
  | {
      component: React.ReactNode;
    }
  | DefaultFallbackProps;

export const DefaultFallback = (props: FallBackProps) => {
  const colorModeColor = useColorModeValue('complete.500', 'complete.100');

  if ('component' in props) {
    return <>{props.component}</>;
  }

  const {
    message = 'This page is empty.',
    content,
    info,
    button,
    image = '/const-04.svg',
  } = props;

  return (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      height={'calc(100vh - 100px)'}
    >
      <Flex flexDir={'column'} gap={4}>
        {!content && (
          <Text
            textAlign={'center'}
            variant={'subtitle'}
            whiteSpace={'pre-line'}
          >
            {message}
          </Text>
        )}
        {content}
        {!!info && (
          <Text
            variant={'subtitle-bold'}
            textAlign="center"
            color={colorModeColor}
            whiteSpace={'pre-line'}
          >
            {info}
          </Text>
        )}
        {!!button && button}
        {typeof image === 'string' ? (
          <Image
            alt="not found"
            src={image}
            height={284}
            width={284}
            layout="responsive"
            priority={true}
            draggable={false}
          />
        ) : (
          image
        )}
      </Flex>
    </Flex>
  );
};

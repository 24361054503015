import { ChakraProvider } from '@chakra-ui/react';
import { themeV2 } from '@tb/ui';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { enUS, registerLanguageDictionary } from 'handsontable/i18n';
import { registerAllModules } from 'handsontable/registry';
import { HyperFormula } from 'hyperformula';
import { NextPage } from 'next';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { AppProps, AppType } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { ChatwootScript } from '~/components/common/Chatwoot';
import { trpc } from '~/utils/trpc';

// Styles
import '@tb/sheets/dist/styles.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-datepicker/dist/react-datepicker.css';
import '~/styles/custom-uppy.css';
import '~/styles/globals.css';
import '~/styles/gridStyles.css';

registerAllModules();
registerLanguageDictionary('en-US', enUS);

export const hyperformulaInstance = HyperFormula.buildEmpty({
  licenseKey: 'internal-use-in-handsontable',
});

type AppPropsWithSession = AppProps<{ session: Session }> & {
  Component: NextPageWithLayout;
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (_page: ReactElement) => ReactNode;
};

// const ignoredPages = ['/auth/login', '/auth/signup', '/auth/forgot-password'];
// Short circuit if on ignored pages
//   if (ignoredPages.includes(router.pathname)) return children;

const MyApp = (({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithSession) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SessionProvider session={session}>
      <ChakraProvider theme={themeV2}>
        <ChatwootScript />
        {getLayout(<Component {...pageProps} />)}
        <SpeedInsights />
      </ChakraProvider>
      <Analytics />
    </SessionProvider>
  );
}) as AppType;

export default trpc.withTRPC(MyApp);

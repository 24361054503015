import { z } from 'zod';
import { CsiCode, Trade } from './csi';
import { DollarAmount } from './currency';
import { BidId, IDNumber, ProjectId } from './ids';
import { TextMax255 } from './validators';

// Bid Stage
export const BidStage = z.enum(['QUALIFY', 'RFP', 'PROCESS', 'LEVEL', 'AWARD']);
export type BidStage = z.infer<typeof BidStage>;

// Bid Status
export const BidStatus = z.enum([
  'UNDECIDED',
  'BIDDING',
  'BID SUBMITTED',
  'AWARDED',
  'DECLINED',
  'SELF PERFORM',
  'CANCELLED',
  'LEVELED',
  'PENDING',
]);
export type BidStatus = z.infer<typeof BidStatus>;

// Bid Qualification
export const BidQualification = z.enum([
  'TO_BE_QUALIFIED',
  'NOT_QUALIFIED',
  'QUALIFIED',
]);
export type BidQualification = z.infer<typeof BidQualification>;

// Bid
export const Bid = z.object({
  id: BidId,
  name: TextMax255,
  projectName: z.string(),
  project: z.object({
    id: ProjectId,
    name: z.string(),
    bidDueDate: z.date().optional().nullish(),
    rfiDueDate: z.date().optional().nullish(),
  }),
  csiCode: CsiCode.optional(),
  trades: z.array(Trade),
  stage: BidStage,
  cost: DollarAmount,
  awardedCost: DollarAmount.optional(),
  dueDate: z.date(),
  preBidRFIDueDate: z.date().optional(),
  expectedStart: z.date().optional(),
  expectedFinish: z.date().optional(),
  bidFormId: IDNumber.optional(),
  cancelled: z.boolean().default(false),
  deleted: z.boolean().default(false),
});
export type Bid = z.infer<typeof Bid>;

// Sub bid
export const SubBid = Bid.extend({
  gc: z.object({
    name: z.string(),
  }),
  bidderId: z.number(),
  status: BidStatus,
});
export type SubBid = z.infer<typeof SubBid>;

// export function getUrl(bid: Bid) {
//   return getDeployedUrl(`bids/${bid.id}`);
// }

export const colors = {
  complete: {
    100: '#12B856',
    200: '#12B856',
    300: '#12B856',
    400: '#037632',
    500: '#037632',
    600: '#037632',
    700: '#025725',
    800: '#025725',
    900: '#025725',
  },
  diagnostics: {
    100: '#DF7098',
    200: '#DF7098',
    300: '#DF7098',
    400: '#B81257',
    500: '#B81257',
    600: '#B81257',
    700: '#760333',
    800: '#760333',
    900: '#760333',
  },

  // Reference: https://www.figma.com/file/GUrNqzGQm5O3eFt10QeFKG/Truebuilt-Components?node-id=41%3A8259&mode=dev

  primary: {
    '50': '#EAF5EF',
    '100': '#BDDECE',
    '200': '#9DCFB7',
    '300': '#71B996',
    '400': '#55AB81',
    '500': '#2B9662',
    '600': '#278959',
    '700': '#1F6B46',
    '800': '#185336',
    '900': '#123F29',
  },
  secondary: {
    50: '#FEECF0',
    100: '#FCC5CF',
    200: '#FAA9B8',
    300: '#F88197',
    400: '#F76983',
    500: '#F54364',
    600: '#DF3D5B',
    700: '#AE3047',
    800: '#872537',
    900: '#671C2A',
  },
  success: {
    50: '#E6F2EB',
    100: '#B0D7C0',
    200: '#8AC4A1',
    300: '#54A976',
    400: '#33985C',
    500: '#007E33',
    600: '#00732E',
    700: '#005924',
    800: '#00451C',
    900: '#003515',
  },
  warning: {
    50: '#FFF3E6',
    100: '#FFDAB0',
    200: '#FFC88A',
    300: '#FFAF54',
    400: '#FFA033',
    500: '#ff8800',
    600: '#E87C00',
    700: '#B56100',
    800: '#8C4B00',
    900: '#6B3900',
  },
  error: {
    50: '#FEECEB',
    100: '#FCC5C1',
    200: '#FAA9A3',
    300: '#F88178',
    400: '#F6695E',
    500: '#F44336',
    600: '#DE3D31',
    700: '#AD3026',
    800: '#86251E',
    900: '#661C17',
  },
  brandGray: {
    50: '#F7F8F8',
    100: '#E5E8EA',
    200: '#D8DDE0',
    300: '#C6CDD2',
    400: '#BBC3C9',
    500: '#AAB4BC',
    600: '#9BA4AB',
    700: '#798085',
    800: '#5E6367',
    900: '#474C4F',
  },
  brand: {
    main: '#E5E7EB',
    light: '#F5F5F5',
    navLight: '#F9F9F9',
    navActive: '#DDEAE2',
    mediumGray: '#E0E0E0',
    gray: '#A1A1A1',
    softGray: '#D9D9D9',
    softLight: '#DFDFDF',
    superLight: '#FAFAFA',
    hold: '#B1B1B1',
    dark: '#858585',
    mediumLight: '#767676',
    closed: '#242424',
    black: '#000000',
    white: '#FFFFFF',
    darkGray: '#5D5E60',
    green: '#12B856',
    softGreen: '#E0F5E8',
    orange: '#FB9C0D',
    softOrange: '#FEF5E9',
    purple: '#7E3ADF',
    softPurple: '#EFE3FF',
    blue: '#2BA3E7',
    softBlue: '#D1EEFF',
    softRed: '#FFEFEF',
    unresolved: '#FF4D6D',
    resolved: '#2FB219',
    greenDark: '#2A7347',
    teal: '#008080',
    softTeal: '#c2ffff',
  },
};

import { Button, Flex, FlexProps, ModalProps } from '@chakra-ui/react';
import { ReactElement, JSXElementConstructor } from 'react';

// type Variant =
//   | 'primary'
//   | 'primary-link'
//   | 'primary-light'
//   | 'outline'
//   | 'outline-thin'
//   | 'primary-thin'
//   | 'outline-transparent'
//   | 'outline-dark'
//   | 'outline-dark-thin'
//   | 'outline-light'
//   | 'outline-red'
//   | 'nav'
//   | 'nav-icon'
//   | 'compact-nav'
//   | 'awarded-hover'
//   | 'awarded';

// const Variant = z.enum([
//   'primary',
//   'primary-link',
//   'primary-light',
//   'outline',
//   'outline-thin',
//   'primary-thin',
//   'outline-transparent',
//   'outline-dark',
//   'outline-dark-thin',
//   'outline-light',
//   'outline-red',
//   'nav',
//   'nav-icon',
//   'compact-nav',
//   'awarded-hover',
//   'awarded',
// ]);

// type Variant = z.infer<typeof Variant>;

export type CancelConfirmButtonProps = {
  primaryActionButton?: {
    text?: string;
    onClick?: () => void;
    variant?: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    testId?: string;
    type?: string;
  };
  secondaryActionButton?: {
    text?: string;
    onClick: () => void;
    variant?: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    testId?: string;
    show?: boolean;
  };
  cancelButton?: {
    text?: string;
    variant?: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
    testId?: string;
    onClick?: () => void;
    show?: boolean;
  };
  buttonSize?: string;
  isDisabled?: boolean;
  modalProps?: ModalProps;
  buttonContainerProps?: FlexProps;
};

const DEFAULT_PRIMARY_ACTION_VALUES = {
  text: undefined,
  variant: 'primary',
  onClick: () => {},
  icon: undefined,
  testId: undefined,
};

const DEFAULT_SECONDARY_ACTION_VALUES = {
  text: undefined,
  onClick: () => {},
  variant: 'outline',
  icon: undefined,
  testId: undefined,
  show: true,
};

const DEFAULT_CANCEL_VALUES = {
  text: undefined,
  onClick: () => {},
  variant: 'outline',
  icon: undefined,
  testId: undefined,
  show: true,
};

export const CancelConfirmButtons = ({
  primaryActionButton = DEFAULT_PRIMARY_ACTION_VALUES,
  secondaryActionButton = DEFAULT_SECONDARY_ACTION_VALUES,
  cancelButton = DEFAULT_CANCEL_VALUES,
  buttonSize,
  isDisabled,
  buttonContainerProps,
}: CancelConfirmButtonProps) => {
  const getPrimaryActionButton = () => {
    if (primaryActionButton.type === 'submit') {
      return (
        <Button
          type={primaryActionButton.type}
          variant={primaryActionButton.variant}
          leftIcon={primaryActionButton.icon}
          size={buttonSize}
          isDisabled={isDisabled}
        >
          {primaryActionButton.text}
        </Button>
      );
    }
    return (
      <Button
        onClick={primaryActionButton.onClick}
        variant={primaryActionButton.variant}
        leftIcon={primaryActionButton.icon}
        size={buttonSize}
        isDisabled={isDisabled}
      >
        {primaryActionButton.text}
      </Button>
    );
  };

  const getSecondaryActionButton = () => {
    return (
      <Button
        onClick={secondaryActionButton?.onClick}
        variant={secondaryActionButton?.variant}
        leftIcon={secondaryActionButton?.icon}
        size={buttonSize}
        isDisabled={isDisabled}
      >
        {secondaryActionButton?.text}
      </Button>
    );
  };

  const getCancelButton = () => {
    return (
      <Button
        onClick={cancelButton?.onClick}
        variant={cancelButton?.variant}
        leftIcon={cancelButton?.icon}
        size={buttonSize}
        isDisabled={isDisabled}
      >
        {cancelButton?.text}
      </Button>
    );
  };

  return (
    <Flex
      w={'100%'}
      gap={2}
      justifyContent={'flex-end'}
      {...buttonContainerProps}
    >
      {cancelButton.text ? getCancelButton() : null}
      {secondaryActionButton.text && secondaryActionButton.show
        ? getSecondaryActionButton()
        : null}
      {primaryActionButton.text ? getPrimaryActionButton() : null}
    </Flex>
  );
};

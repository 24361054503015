import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  variants: {
    primary: ({ colorMode }) => ({
      color: 'brand.white',
      bg: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      _hover: {
        bg: colorMode === 'dark' ? 'complete.500' : 'complete.700',
        _disabled: {
          bg: colorMode === 'dark' ? 'complete.500' : 'complete.700',
        },
      },
      _active: {
        bg: 'complete.700',
      },
      shadow: 'base',
    }),
    'primary-link': {
      color: 'complete.500',
      bg: 'transparent',
      _hover: {
        bg: 'transparent',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'transparent',
      },
      shadow: 'none',
    },
    'primary-light': {
      color: 'brand.white',
      bg: 'complete.100',
      _hover: {
        bg: 'complete.500',
      },
      _active: {
        bg: 'complete.700',
      },
    },
    outline: ({ colorMode }) => ({
      color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      border: '1px solid',
      borderColor: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      _hover: {
        bg: colorMode === 'dark' ? 'complete.100' : 'complete.500',
        color: 'brand.white',
        _disabled: {
          bg: 'brand.white',
          color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
        },
      },
    }),

    'outline-red': {
      color: 'red.500',
      border: '1px solid',
      borderColor: 'brand.unresolved',
      _hover: {
        color: 'brand.white',
        _disabled: {
          color: 'red.500',
        },
      },
    },
    'outline-blue': {
      color: 'brand.blue',
      border: '1px solid',
      borderColor: 'brand.blue',
      _hover: {
        bg: 'brand.blue',
        color: 'brand.white',
        _disabled: {
          color: 'brand.blue',
        },
      },
    },
    'outline-thin': {
      color: 'complete.500',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'complete.500',
      height: 7,
    },
    'primary-thin': {
      color: 'brand.white',
      bg: 'complete.500',
      _hover: {
        bg: 'complete.700',
        _disabled: {
          bg: 'complete.700',
        },
      },
      _active: {
        bg: 'complete.700',
      },
      shadow: 'base',
      height: 7,
    },
    'outline-transparent': {
      color: 'complete.500',
      border: '1px solid',
      borderColor: 'complete.500',
    },
    'outline-dark': {
      color: 'brand.darkGray',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'brand.dark',
      _hover: {
        color: 'brand.black',
        borderColor: 'brand.black',
      },
    },
    'outline-dark-thin': {
      color: 'brand.darkGray',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'brand.dark',
      _hover: {
        color: 'brand.black',
        borderColor: 'brand.black',
      },
      height: 7,
    },
    'outline-light': {
      color: 'brand.darkGray',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'brand.dark',
      fontWeight: 'normal',
      _hover: {
        color: 'brand.black',
        borderColor: 'brand.black',
      },
    },

    nav: {
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      fontWeight: 'normal',
      borderRadius: 'lg',
      margin: '7px 0',
      _hover: {
        borderRadius: 'lg',
      },
      _active: {
        borderRadius: 'lg',
        fontWeight: 'bold',
      },
      shadow: 'none',
    },
    'nav-icon': ({ colorMode }) => ({
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      fontWeight: 'normal',
      borderRadius: 'lg',
      margin: '7px 0',
      _hover: {
        color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      },
      _active: {
        color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      },
      _focus: {
        color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      },
    }),
    'compact-nav': {
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      padding: '3',
      _hover: {
        bg: 'brand.navActive',
        color: 'complete.500',
      },
      _active: {
        bg: 'brand.navActive',
        color: 'complete.500',
      },
    },
    'awarded-hover': {
      backgroundColor: '#F2F2F2',
      _hover: {
        backgroundColor: 'brand.softOrange',
        color: 'brand.orange',
      },
    },
    'action-icon': ({ colorMode }) => ({
      color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      bg: 'transparent',
      border: 'none',
      fontWeight: 'normal',
      borderRadius: 'lg',
      _hover: { bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100' },
    }),
    awarded: {
      backgroundColor: 'brand.softOrange',
      color: 'brand.orange',
    },
    link: ({ colorMode }) => ({
      color: colorMode === 'light' ? 'gray.700' : 'brand.white',
    }),
    icon: { backgroundColor: 'transparent' },
  },
};

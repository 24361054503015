import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdClose, MdSearch } from 'react-icons/md';

/* eslint-disable-next-line */
export interface SearchInputProps {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInput: string;
  loading?: boolean;
  handleClear?: () => void;
  disabled?: boolean;
  padding?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const SearchInput = ({
  handleSearch,
  searchInput,
  loading,
  handleClear,
  disabled = false,
  padding = '10px 1px',
  size = 'sm',
}: SearchInputProps) => {
  const inputBg = useColorModeValue('white', 'gray.600');
  const buttonsBg = useColorModeValue('white', 'gray.600');

  return (
    <InputGroup size={size} padding={padding}>
      <InputLeftAddon bg={buttonsBg}>
        <Icon as={MdSearch} />
      </InputLeftAddon>
      <Input
        placeholder="Search"
        onChange={handleSearch}
        value={searchInput}
        isDisabled={disabled}
        bg={inputBg}
      />
      {!!handleClear && !!searchInput && (
        <InputRightAddon p={0} m={0} bg={buttonsBg}>
          <IconButton
            variant={'link'}
            aria-label="close"
            icon={<MdClose />}
            onClick={handleClear}
          />
        </InputRightAddon>
      )}
      {!!loading && (
        <InputRightAddon bg={buttonsBg}>
          <Spinner />
        </InputRightAddon>
      )}
    </InputGroup>
  );
};
